<template>
  <div class="section-title">
    <div class="ch-title" v-if="chTitle">
      {{chTitle}}
    </div>
    <div class="en-title"  v-if="enTitle">
        {{enTitle}}
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      chTitle:String,
      enTitle:{
        type:String,
        default:''
      }
    },
    data() {
      return {

      }
    }
  }
</script>

<style>
  .section-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 60px;
    padding-left: 10px;
    border-left: 2px solid #23a7f3;
  }
  .ch-title{
    font-size: 21px;
    color: #535353;
  }
  .en-title{
    margin-top: 5px;
    font-size: 16px;
    color:#aaaaaa;
  }
</style>
